import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Slider } from '../../../common/Slider';
import { HubAudioPlayer } from '../../../common/HubAudioPlayer';
import { ReactComponent as Intro1 } from '../../../../images/svg/intro/intro1.svg';
import { ReactComponent as Intro2 } from '../../../../images/svg/intro/intro2.svg';
import { ReactComponent as Intro3 } from '../../../../images/svg/intro/intro3.svg';
import { Wrapper } from '../../../layout/Wrapper';
import { Icon } from '../../../common/Icon/Icon';

import styles from './Intro.module.scss';

const SVGS = [Intro1, Intro2, Intro3];
const slideClasses = [styles.slideOne, styles.slideTwo, styles.slideThree];

const Intro = ({ slides }) => {
  const introSlides = slides;
  const sliderRef = useRef();

  const [slideIndex, setSlideIndex] = useState(0);

  const onIndexChanged = useCallback(e => {
    setSlideIndex(e.index);
  }, []);

  const onRef = useCallback(ref => {
    if (ref) {
      sliderRef.current = ref;
    }
  }, []);

  const onPrev = useCallback(() => {
    if (sliderRef.current) sliderRef.current.slider.goTo('prev');
  }, []);

  const onNext = useCallback(() => {
    if (sliderRef.current) sliderRef.current.slider.goTo('next');
  }, []);

  const onJump = useCallback(() => {
    window.scrollBy({
      top:
        document.getElementById('jumpcontent').getBoundingClientRect().top -
        document.querySelector('header').getBoundingClientRect().height,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className={classNames(styles.slider, styles[`slider${slideIndex}`])}>
      <Slider
        onIndexChanged={onIndexChanged}
        onRef={onRef}
        settings={{ controls: false, nav: false, loop: false, gutter: 40 }}
      >
        {introSlides.map((slide, index) => {
          const SvgEl = SVGS[index % SVGS.length];

          return (
            <div key={index} className={classNames(styles.slide, slideClasses[index % slideClasses.length])}>
              <div className={styles.slideInner}>
                <SvgEl preserveAspectRatio="none" />
                <div className={styles.slideContent}>
                  <Wrapper className={styles.slideWrapper}>
                    <p className={styles.text}>{slide.homeSlideText}</p>
                    <div className={styles.audio}>
                      <HubAudioPlayer
                        src={slide.homeSlideAudioAlternative?.localFile?.publicURL}
                        label="Listen"
                        active={index === slideIndex}
                      />
                    </div>
                  </Wrapper>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>

      <div className={styles.controls}>
        <button
          className={classNames(styles.control, styles.controlPrev)}
          onClick={onPrev}
          aria-label="next"
          tabIndex={slideIndex !== 2 ? 0 : -1}
        >
          <Icon size="free" name="arrow-drawn" mirror />
        </button>
        <button
          className={classNames(styles.control, styles.controlNext)}
          onClick={onNext}
          aria-label="next"
          tabIndex={slideIndex !== 2 ? 0 : -1}
        >
          <Icon size="free" name="arrow-drawn" />
        </button>
        <button
          className={classNames(styles.control, styles.controlLast)}
          onClick={onJump}
          aria-label="previous"
          tabIndex={slideIndex === 2 ? 0 : -1}
        >
          <Icon size="free" name="arrow-drawn-down" />
        </button>
      </div>
    </div>
  );
};

Intro.propTypes = {
  slides: PropTypes.array.isRequired,
};

export { Intro };
