import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import SEO from '../components/common/seo';
import { Wrapper } from '../components/layout/Wrapper';
import { Intro } from '../components/pagelayout/home/Intro';
// import { TextSvg } from '../components/common/TextSvg';
import { TagsSectionFooter } from '../components/layout/TagsSectionFooter';
// import { ReactComponent as LabelSvg } from '../images/svg/label.svg';
// import { getCategoryColor, useAppContext } from '../components/context/AppContext';
import { getCategoryColor } from '../components/context/AppContext';

import { CategoriesListing } from '../components/layout/CategoriesListing';
import { HandwrittenTitle } from '../components/common/HandwrittenTitle';
import styles from './Index.module.scss';

export const pageQuery = graphql`
  query {
    allSlides: wpPage(slug: { eq: "hub-home" }) {
      homeSlider {
        homeSlides {
          homeSlideText
          homeSlideTitle
          homeSlideAudioAlternative {
            localFile {
              publicURL
            }
          }
        }
      }
    }

    allCategories: allWpCategory(
      sort: { order: ASC, fields: categoryExtraFields___order }
      filter: { name: { ne: "Uncategorised" } }
    ) {
      edges {
        node {
          slug
          name
          description
          categoryExtraFields {
            comingSoon
          }
        }
      }
    }
  }
`;

const HomePage = ({ data }) => {
  /* COMMENTED OUT EVENTS RELATED ITEMS */
  // const { toggleEvents } = useAppContext();
  const categories = data.allCategories.edges;
  const slides = data.allSlides.homeSlider.homeSlides;

  return (
    <>
      <SEO title="Home" />
      <Helmet
        bodyAttributes={{
          class: getCategoryColor(),
        }}
      />

      {/* Slides */}
      <Intro slides={slides} />

      <Wrapper id="jumpcontent" wrapped border padded>
        {/* Categories */}
        <div>
          <HandwrittenTitle className={styles.exploreTitle} title="Explore" />
          <p>See and hear our stories from the project – choose a subject to explore</p>

          <CategoriesListing categories={categories} />
        </div>
      </Wrapper>
      <Wrapper wrapped padded>
        <TagsSectionFooter />
      </Wrapper>

      {/* <TextSvg className={styles.label} tagEl="button" svg={LabelSvg} onClick={() => toggleEvents(true)}>
        Events
      </TextSvg> */}
    </>
  );
};
export default HomePage;
