import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import { TextSvg } from '../../common/TextSvg';
import { HandwrittenTitle } from '../../common/HandwrittenTitle';
import { Button } from '../../common/Button';
import { ReactComponent as ComingSoonSvg } from '../../../images/svg/coming-soon.svg';

import styles from './CategoriesListing.module.scss';

const CategoriesListing = ({ className, categories, large }) => {
  return (
    <div className={classNames(styles.wrapper, { [styles.large]: large }, className)}>
      {categories &&
        categories.map((category, index) => (
          <div className={classNames(styles.wrapperColumn, category.node.slug)} key={category.node.slug}>
            <TextSvg
              className={styles.wrapperColumnText}
              tagEl={category.node.categoryExtraFields?.comingSoon ? undefined : Link}
              innerClassName={styles.wrapperColumnInner}
              to={category.node.categoryExtraFields?.comingSoon ? undefined : `/${category.node.slug}`}
              svg={`Circle00${(index % 4) + 1}`}
              key={category.node.slug}
            >
              <span className={styles.one}>
                <HandwrittenTitle className={styles.handwritten} responsive narrow title={category.node.name} />
              </span>
              <span className={styles.two}>
                {category.node.categoryExtraFields?.comingSoon ? (
                  <ComingSoonSvg className={styles.wrapperSoon} />
                ) : (
                  <>
                    <span>{category.node.description}</span>

                    <Button size="normal" className={styles.btn}>
                      More...
                    </Button>
                  </>
                )}
              </span>
            </TextSvg>
            {large && <div className={styles.wrapperColumnDescription}>{category.node.description}</div>}
          </div>
        ))}
    </div>
  );
};

export { CategoriesListing };
